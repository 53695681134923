import React from "react"
import styled from "styled-components"
import BodyWrapper from "../components/BodyWrapper"
import GImg from "gatsby-image"

import {
  IconPopbar,
  PopBar,
  StroopImage,
  PopbarGif,
  closeUp1,
  closeUp2,
  closeUp3,
} from "../utils/imageUpload.js"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
const Popbar = ({ data }) => {
  const [banner] = data.allFile.edges.map(({ node }) => node)

  return (
    <Layout>
      <SEO title="Popbar Belgian Boys" />
      <Landing>
        {" "}
        <GImg fluid={banner.childImageSharp.fluid} />
      </Landing>
      <Info>
        <BodyWrapper>
          <div className="wrapper">
            <div className="main-info">
              <img src={PopbarGif} alt="" />
              <p>
                It's our favorite day of the year, National Waffle Day! We
                couldn't think of a better way to celebrate than to combine the
                crunchy gooeyness of the classic Belgian Boys Dutch Caramel
                Stroopwafel with the creamy decadence of a PopBar gelatoShake...
                and Voila! The
                <b> Stroopwafel gelatoShake </b>
                was born! It's a milkshake reinvented, where gelato is mixed
                with crumbly stroopwafel bits and served in a milk-jug style cup
                with a chocolate dipped waffle cone overflowing with whipped
                cream. If that doesn't say celebration, we don't know what does!
              </p>
            </div>

            <div className="closeUp-container">
              <div>
                <img src={closeUp1} alt="" />
              </div>
              <div>
                <img src={closeUp2} alt="" />
              </div>
              <div>
                <img src={closeUp3} alt="" />
              </div>
            </div>
            <div className="available-container">
              <h1>AVAILABLE AT SELECT LOCATIONS</h1>
              <h3>Starting August 24, 2020, until supplies last</h3>

              <div className="grid">
                {Locations.map(el => {
                  return (
                    <a target="__blank" href={el.link}>
                      <img width="40px" src={IconPopbar} alt="" />
                      <p>{el.name}</p>
                      <p className="light">{el.sub ? el.sub : ""}</p>
                    </a>
                  )
                })}
              </div>
            </div>
            <div>
              <Link className="button" to="/product/single-stroopwafel">
                SHOP DUTCH CARAMEL STROOPWAFELS
              </Link>
            </div>
            <div className="stroop">
              <img src={StroopImage} alt="" />
            </div>
          </div>
        </BodyWrapper>
      </Info>
    </Layout>
  )
}

export default Popbar

const Landing = styled.div`
  min-height: 70vh;

  /* padding: 20px 0; */

  background-color: rgb(254, 161, 208);
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  img {
    width: 80%;
    object-fit: fill;
  }
  @media (max-width: 769px) {
    min-height: 30vh;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 1500px) {
    min-height: 50vh;
  }
`
const Info = styled.div`
  padding-top: 50px;
  background: var(--beige);

  .main-info {
    display: grid;
    align-items: center;
    justify-content: center;

    grid-gap: 5rem;
    grid-template-columns: repeat(auto-fit, 400px);

    @media (max-width: 900px) {
      grid-template-columns: repeat(auto-fit, 300px);
    }
    color: var(--darkBlue);

    img {
      width: 100%;
    }

    p {
      font-weight: 500;
      font-size: 20px;
      /* text-align: center; */
    }
  }

  .available-container {
    margin-top: 100px;
    text-align: center;
    h1 {
      color: var(--darkBlue);
      margin-bottom: 15px;
    }

    h3 {
      font-weight: 600;
      color: var(--pink);
      margin-bottom: 45px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 200px);
    @media (max-width: 900px) {
      grid-template-columns: repeat(auto-fit, 150px);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(2, auto);
    }
    grid-gap: 2rem;
    margin-bottom: 100px;
    justify-content: center;

    a {
      text-decoration: none;
      :hover {
        text-decoration-line: underline;
        color: var(--darkBlue);
      }
    }
    p {
      font-weight: 600;
      color: var(--darkBlue);
    }

    .light {
      font-weight: 300;
    }
  }

  .button {
    margin: 40px 0;
    /* padding: 20px; */
    background-color: var(--darkBlue);
    @media (max-width: 700px) {
      width: 70%;
    }
    width: 40%;

    color: white;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
    border: none;
    margin-bottom: 5rem;
    text-decoration: none;

    padding: 20px;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    :hover {
      background: var(--pink);
    }
  }

  .stroop {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
      height: 50%;
    }
  }

  .closeUp-container {
    margin: 50px 0;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-between;

    /* grid-gap: 2rem; */
    img {
      width: 100%;
    }
  }

  .wrapper {
    margin: 0 auto;
    max-width: var(--hugeInNav);

    @media (max-width: 1200px) {
      margin: 0 auto;
      max-width: var(--bigWidth);
    }

    @media (max-width: 1000px) {
      margin: 0 auto;
      max-width: var(--laptopWidth);
    }

    @media (max-width: 900px) {
      margin: 0 auto;
      /* max-width: var(--tabletWidth); */
      max-width: var(--smallLaptopWidth);
    }

    @media (max-width: 700px) {
      margin: 0 auto;
      max-width: var(--tabletWidth);
    }

    @media (max-width: 600px) {
      margin: 0 auto;
      max-width: var(--phoneWidth);
    }
  }
`

const Locations = [
  {
    name: "CHARLOTTE, NC",
    link: "https://www.pop-bar.com/pages/charlotte-nc",
  },
  {
    name: "CONCORD MILLS, NC",
    link: "https://www.pop-bar.com/pages/concord-mills-nc",
  },
  { name: "ANAHEIM, CA", link: "https://www.pop-bar.com/pages/anaheim-ca" },
  {
    name: "RANCHO CUCAMONGA, CA",
    link: "https://www.pop-bar.com/pages/rancho-cucamonga-ca",
  },

  {
    name: "SACRAMENTO, CA",
    link: "https://www.pop-bar.com/pages/sacramento-ca",
  },

  {
    name: "HUNTINGTON BEACH, CA",
    link: "https://www.pop-bar.com/pages/huntington-beach-ca",
  },

  {
    name: "CONCORD, CA",
    link: "https://www.pop-bar.com/pages/concord-ca",
  },

  {
    name: "LONG BEACH, CA",
    link: "https://www.pop-bar.com/pages/long-beach-ca",
  },

  {
    name: "ARCADIA, CA",
    link: "https://www.pop-bar.com/pages/arcadia-ca",
  },

  {
    name: "ORLANDO, FL",
    sub: "(Vineland Premium Outlets)",
    link: "https://www.pop-bar.com/pages/orlando-fl-vineland-premium-outlets",
  },
  {
    name: "ORLANDO, FL",
    sub: "(FL International Premium Outlets)",
    link:
      "https://www.pop-bar.com/pages/orlando-fl-international-premium-outlets",
  },
]

export const query = graphql`
  query PopbarPage {
    allFile(
      filter: {
        sourceInstanceName: { eq: "pagebanners" }
        relativeDirectory: { eq: "popbar" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
